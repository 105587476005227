import type { Ref } from 'vue'

export function useRouteQuery(name: string): Ref<null | string | string[]>
export function useRouteQuery<
  T extends null | undefined | string | string[] = null | string | string[],
>(name: string, defaultValue?: T, options?: any): Ref<T>
export function useRouteQuery<T extends string | string[]>(
  name: string,
  defaultValue?: T,
  { mode = 'replace', route = useRoute(), router = useRouter() }: any = {},
) {
  return computed<any>({
    get() {
      const data = route.query[name]
      if (data == null) return defaultValue ?? null
      if (Array.isArray(data)) return data.filter(Boolean)
      return data
    },
    async set(v) {
      await nextTick()

      router[unref(mode)]({
        ...route,
        query: {
          ...route.query,
          [name]: v === defaultValue || v === null ? undefined : v,
        },
      })
    },
  })
}
